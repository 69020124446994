<template lang="pug">
#Documents
  .upload-label {{ $t('lang.checkin.uploadPassport') }}
  FileUpload(v-model="PASSPORT_FILE", :preview="passportFilePreview" id="file-passport")
  .button-group(style="margin-top: 16px;")
    el-button#explora-btn.secondary(@click="goBack") {{ $t('lang.control.button.goBack') }}
    el-button#explora-btn.primary(@click="uploadFiles", :disabled="!canContinue" :loading="!canContinue") {{ $t('lang.control.button.continue') }}
</template>
<script>
import Header from '@/components/Header.vue'
import FileUpload from '@/components/FileUpload.vue';
import { mapActions, mapGetters } from 'vuex';
import { Loading } from 'element-ui';
import Compress from 'compress.js'

export default {
  props: ['canContinue', 'hotelCountries', 'seletedIndex'],
  components: {
    Header,
    FileUpload,
  },
  data () {
    return {
      PASSPORT_FILE: null,
      passportPreview: null,
      compressor: null,
    }
  },
  watch: {
    loadingDocs() {
      if (this.loadingDocs) {
        Loading.service({ fullscreen: true });
      } else {
        Loading.service().close();
      }
    },
    passportFile() {
      if (this.passportFile) {
        this.createFileFromUrl(this.passportFile)
          .then(file => {
            this.PASSPORT_FILE = file;
          })
          .catch(error => {
            console.error('Error al obtener el archivo:', error);
          });
      }
    },
    passportFilePreview() {
      if (this.passportFilePreview) {
        this.passportPreview = this.passportFilePreview
      }
    },
  },
  computed: {
    ...mapGetters(['travellersCompanion', 'travellerProfileLink', 'pdiFile', 'passportFile', 'pdiFilePreview', 'passportFilePreview', 'isSigned', 'traveller', 'reservation', 'allReservations', 'loadingDocs']),
  },
  created() {
    this.compressor = new Compress()
    Loading.service({ fullscreen: true });
  },
  methods: {
    ...mapActions(['AddOrUpdateAttachment']),
    async createFileFromUrl(url) {
      const response = await fetch(url);
      const buffer = await response.arrayBuffer();
      const contentType = response.headers.get('content-type');
      const fileName = url.substring(url.lastIndexOf('/') + 1);
      const blob = new Blob([buffer], { type: contentType });
      const file = new File([blob], fileName, { type: contentType });
      return file;
    },
    goBack() {
      window.location.replace(this.travellerProfileLink)
    },
    async upload() {
      let allOkey = true
      if (this.PASSPORT_FILE === null) {
        allOkey = false
        this.$notify({
          title: this.$t('lang.attachments.problem'),
          message: this.$t('lang.attachments.noFileError'),
          type: 'error'
        })
      } else {
        const fileReader = new FileReader()
        const file = this.PASSPORT_FILE
        fileReader.readAsDataURL(file)

        let blob = file
        const isImage = file.type.includes('image')
        if (isImage) {
          try {
            blob = await this.compressor.compress(file, {
              quality: 0.9,
              crop: false,
              maxWidth: 500,
              maxHeight: 500,
            })
          } catch (error) {
            blob = file
          }
        }

        const dataTicket = new FormData()

        dataTicket.append('reservation_id', this.reservation.id)
        dataTicket.append('token', this.$route.params.profileToken)

        dataTicket.append(`file_type[0]`, 'PASSPORT_FILE')
        dataTicket.append(`attachment[0]`, blob)

        this.$notify({
          title: this.$t('lang.attachments.uploading'),
          message: this.$t('lang.attachments.upDescription'),
          type: 'info'
        })
        const resp = await this.AddOrUpdateAttachment(dataTicket)
        if (resp.status !== 200) allOkey = false
        this.$notify({
          title: resp.status === 200 ? this.$t('lang.attachments.ready') : this.$t('lang.attachments.problem'),
          message: resp.status === 200 ? this.$t('lang.attachments.uploaded') : this.$t('lang.attachments.generalError'),
          type: resp.status === 200 ? 'success' : 'error'
        })
      }

      if (allOkey) {
        if (this.traveller.age < 18) {
          this.$emit('changeStep', 4)
        } else {
          if (this.isSigned) {
            // this.$emit('changeStep', 6)
            this.$emit('changeStep', 4)
          } else {
            const isHolder = parseInt(this.reservation.partyCode) === parseInt(this.reservation.confirmationNumber)
            if (this.travellersCompanion && this.travellersCompanion.length > 0 && isHolder) {
              this.$emit('changeStep', 2)
            } else {
              this.$emit('changeStep', 3)
            }
          }
        }
      }
    },
    async uploadFiles () {
      this.upload()
    }
  }
}
</script>
<style lang="scss">
#Documents {
  .box {
    padding: 0 16px;
    text-align: left;
  }
  h3 {
    margin-bottom: 4px !important;
  }
  .el-checkbox__label {
    font-family: Avenir, Helvetica, Arial, sans-serif !important;
    font-size: 16px !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
  }
  .tooltip {
    border: 1px solid black;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    color: black;
    margin-left: 16px;
  }
}
</style>
