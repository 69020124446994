<template lang="pug">
#BorderForm
  h3(v-if="type === 'ARRIVAL'") {{ $t('lang.transport.hotelForm.descriptionArrival') }}
  h3(v-if="type === 'DEPARTURE'") {{ $t('lang.transport.hotelForm.descriptionDeparture') }}
  el-form(ref="form", :model="form", :rules="rules")
    .input-label {{ $t('lang.transport.borderForm.border') }}
    el-form-item(prop="border")
      el-select(v-model="form.border" filterable :placeholder="$t('lang.transport.borderForm.selectBorder')" style="width: 100%;" value-key="id" :id="'borderField-' + type")
        el-option(v-for="b in borders" :key="b.id", :label="b.value" :value="b" :id="'border-' + b.id")
    .button-group
      el-button#explora-btn.secondary(@click="goBackPage") {{ $t('lang.control.button.goBack') }}
      el-button#explora-btn.primary(@click="saveData('form')", :class="`save-${type}-data`") {{ $t('lang.control.button.continue') }}
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'

export default {
  props: ['type', 'changeStep', 'goBack', 'goBackPage', 'legNumber', 'isLast', 'addIndex', 'hotel', 'confirmationNumber', 'loadingRef'],
  data() {
    return {
      form: {
        border: null
      },
      rules: {
        border: [
          { required: true, message: this.$t('lang.transport.borderForm.borderRequired'), trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    ...mapActions(['updateTransport', 'modifyTravellerStepStatus']),
    async saveData (form) {
      this.$refs[form].validate(async (valid) => {
        if (valid) {
          const transport = this.transportsInformation.find(t => t.hotel.id === this.hotel.id && t.transportType.toLowerCase() === 'frontera' && t.id === this.form.border.id)
          const confirmationNumbers = [this.confirmationNumber]

          const h = this.$createElement
          const selection = []

          selection.push(h('el-checkbox', {
            props: {
              key: this.confirmationNumber,
              value: true,
              disabled: true
            },
            style: {
              width: '100%'
            }
          }, `${this.transportData.firstName} ${this.transportData.lastName}`))
          this.transportData.reservationConsulted.partyConfirmations.forEach((res) => {
            this.partyApply.push({
              reservation: res,
              value: false
            })
          })
          this.transportData.reservationConsulted.partyConfirmations.forEach((res) => {
            const reservationCheckVal = this.partyApply.find((obj) => obj.reservation === res)
            selection.push(h('el-checkbox', {
              props: {
               key: res.confirmationNumber
             },
             domProps: { value: reservationCheckVal.value },
             on: { input: (value) => { reservationCheckVal.value = value } }
            }, `${res.travellerObj.firstName} ${res.travellerObj.lastName}`))
          })

          let canContinue = true

          if (parseInt(this.transportData.reservationConsulted.partyCode) === this.confirmationNumber && this.transportData.reservationConsulted.partyConfirmations.length > 0) {
            canContinue = false
            await this.$msgbox({
              title: this.$t('lang.applyTransportParty.message'),
              message: h('div', null, [
                h('p', null, this.$t('lang.applyTransportParty.description')),
                h('br', null, ''),
                selection
              ]),
              showCancelButton: true,
              confirmButtonText: 'Aplicar',
              confirmButtonClass: 'apply-transport-info',
              cancelButtonText: 'Cancelar',
              beforeClose: (action, instance, done) => {
                if (action === 'confirm') {
                  canContinue = true
                  this.partyApply.filter((p) => p.value === true).forEach(p => {
                    confirmationNumbers.push(p.reservation.confirmationNumber)
                  })
                  done()
                } else {
                  done()
                }
              }
            }).then(() => {}).catch(() => {})
          }

          if (canContinue) {
            this.loadingRef.showLoading();
            const nextStep = this.type === 'ARRIVAL' ? 2 : 3
            await this.modifyTravellerStepStatus({ token: this.$route.params.profileToken, travellerId: this.transportData.idTraveller, stepNumber: nextStep, status: 'OK', confirmationNumber: this.confirmationNumber, legNumber: this.legNumber })
            if (this.isLast) {
              this.changeStep(nextStep)
            } else {
              this.addIndex()
            }
            const resp = await this.updateTransport({
              travellerId: this.transportData.idTraveller,
              confirmationNumbers: confirmationNumbers,

              transportId: transport.locationCode,
              transportType: transport.transportTypeOws,
              carrierCode: transport.locationCode,
              locationCode: transport.cityCode,

              date: `${moment().format('YYYY-MM-DD')} ${moment().format('HH:mm')}:00`,
              queryType: this.type,
              legNumber: this.legNumber,
              warning: false,
              destinationType: 'BORDER',
            })
            this.loadingRef.hideLoading();
            if (resp === true) {
              this.$notify({
                title: this.$t('lang.saved.title'),
                type: 'success',
                customClass: 'step-transport-border-complete'
              })
            } else {
              this.$notify({
                title: this.$t('lang.errorSave.title'),
                message: this.$t('lang.errorSave.message'),
                type: 'error'
              })
            }
          }
        } else {
          return false
        }
      })
    }
  },
  computed: {
    ...mapGetters(['transportsInformation', 'transportData']),
    borders () {
      return this.transportsInformation.filter(t =>
        t.transportType.toLowerCase() === 'frontera'
        && t.hotel.id === this.hotel.id
        && (this.type === 'ARRIVAL' && t.showIn || this.type === 'DEPARTURE' && t.showOut)
      )
    }
  }
}
</script>

<style lang="scss">
#BorderForm {
  .input-label {
    font-size: 12px;
    margin: 16px 0;
  }
  .title {
    display: flex;
  }
}
</style>
