import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { createProvider } from './vue-apollo'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale'
import lang from 'element-ui/lib/locale/lang/es'
import '../plugins/fontAwesome'
import i18n from './lang'
import VueAnalytics from 'vue-analytics'
import settings from '../settings'
import Toasted from 'vue-toasted'
import moment from 'moment'
import AvatarCropper from 'vue-avatar-cropper'

import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

locale.use(lang)

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(Toasted)
Vue.use(AvatarCropper)
Vue.component('AvatarCropper', AvatarCropper)

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: settings.sentryDsn,
    integrations: [new Integrations.Vue({ Vue, attachProps: true })]
  })
}

Vue.filter('formatedDate', function (value) {
  if (value && value !== '') {
    return moment(value).format('DD-MM-YYYY')
  }
  return ''
})

Vue.use(VueAnalytics, {
  id: settings.analyticsId
})

new Vue({
  router,
  store,
  i18n,
  apolloProvider: createProvider(),
  render: h => h(App)
}).$mount('#app')
