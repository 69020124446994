<template lang="pug">
div
  .upload-label {{ $t('lang.checkin.signWaiver') }}
  .waiver-box(v-if="urlWaiver && urlWaiver.length > 0")
    PDFReader(:src="urlWaiver")
  hr
  .sign-label {{ $t('lang.checkin.signHere') }}
  #Waiver(v-if="step === 3")
    .signature
      vue-signature(ref='signature' :sigOption='option' :w="'300px'" :h="'130px'" :disabled='disabled' :defaultUrl='dataUrl')
  el-button#explora-btn.primary(@click='clear', style="margin-top: 16px;") {{ $t('lang.checkin.cleanSignature') }}
  .button-group(style="margin-top: 16px;")
    el-button#explora-btn.secondary(@click="goBack") {{ $t('lang.control.button.goBack') }}
    el-button#explora-btn.primary(@click="save") {{ $t('lang.control.button.continue') }}
</template>

<script>
import vueSignature from "vue-signature";
import PDFReader from "./Tools/PDFReader.vue";
import { mapActions, mapGetters } from 'vuex';
import { Loading } from 'element-ui';


export default {
  components: {
    vueSignature,
    PDFReader
  },
  props: ['urlWaiver', 'step'],
  data() {
    return {
      option: {
        penColor: "rgb(0, 0, 0)",
        backgroundColor: "rgb(255,255,255)",
      },
      disabled: false,
      dataUrl: "",
      link: null,
    };
  },
  computed: {
    ...mapGetters(['travellersCompanion', 'reservation', 'allReservations']),
  },
  methods: {
    ...mapActions(['signDocument']),
    goBack () {
      this.$emit('changeStep', 1)
    },
    async save() {
      const loading = Loading.service({ fullscreen: true });
      var _this = this;
      var png = _this.$refs.signature.save("image/jpeg");
      let data = new FormData()
      data.append('attachment', png)
      this.allReservations.forEach((reservation, index) => {
        data.append(`reservation_id[${index}]`, reservation.id)
      })
      data.append('token', this.$route.params.profileToken)
      data.append('travellers_young', JSON.stringify(this.travellersCompanion))
      data.append('lang', localStorage.getItem('lang'))
      const resp = await this.signDocument({ data })
      this.link = resp
      loading.close();
      this.$emit('changeStep', 4)
    },
    clear() {
      var _this = this;
      _this.$refs.signature.clear();
    },
    fromDataURL(url) {
      var _this = this;
      _this.$refs.signature.fromDataURL("data:image/png;base64,iVBORw0K...");
    },
  },
};
</script>
<style lang="scss" scoped>
#Waiver {
  margin-left: 10px;
  display: flex;
  justify-content: center;
}
.signature {
  border: 1px rgb(0, 0, 0) solid;
  border-radius: 5px;
  height: 130px;
}
.sign-label {
  padding-left: 10px;
  text-align: left;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
}
.waiver-box {
  height: 630px;
  overflow-y: auto;
  border: 1px rgb(0, 0, 0) solid;
}
</style>