<template lang="pug">
#TravellerProfile
  Header(v-if="groups && groups.length > 0 && error === null && !loading", :traveller="groups[0].reservations[0].travellerObj")
  .profile(v-if="groups && groups.length > 0 && error === null && !loading")
    div(v-if="thanksMessage === false")
      h1.welcome {{ $t('lang.welcome.welcome') }}
      h1 {{ groups[0].reservations[0].travellerObj.firstName + " " +  groups[0].reservations[0].travellerObj.lastName }}
      h4(style="text-align: left;") {{ $t('lang.welcome.confirmationNumber') + ": " + groups[0].reservations[0].confirmationNumber }}
      div.parraf(v-html="messageParraf")
      .travellers-title {{ $t('lang.welcome.travellers') }}
    div(v-else, style="margin-bottom: 54px;")
      div(style="display: flex; justify-content: center;")
        .icon-circle
          font-awesome-icon(:icon="['fas', 'mountain']", size="4x")
        .check
          font-awesome-icon(:icon="['fas', 'check']", size="m")
      h3 {{ $t('lang.thanksMsg.title') }}
      p {{ $t('lang.thanksMsg.description') }}
      //- el-tooltip(class="item" effect="dark" :content="$t('lang.welcome.checkinTooltip')" placement="top-start")
      //-   center
      //-     .action-btn(style="margin-top: 10px;" v-if="isNearCheckin")
      //-       .el-icon-s-order
      //-       label(style="margin-left: 8px;", @click="goToCheckinForm(groups[0].reservations[0].travellerObj.token, groups[0].reservations[0].confirmationNumber)") {{ $t('lang.control.button.completeCheckin') }}
    el-tabs(v-model='activeName' v-if="readyReservations.length > 0")
      el-tab-pane(:label="$t('lang.attachments.travellers')" name='first')
      //- el-tab-pane(:label="$t('lang.attachments.mobilityPassTitle')" name='second')
    div(v-for="(g, gindex) in groups", style="text-align:left;", v-show="activeName === 'first'")
      h4(v-if="groups.length > 1") {{ $t('lang.share') + " " + g.group }}
      div(v-for="(r, index) in g.reservations", v-bind:key="index")
        avatar(
          :traveller="r.travellerObj"
          :titular="r.titular"
          :index="index"
          :indexGroup="gindex"
          :thanksMessage="thanksMessage"
          :reservation="r"
          @avatarChanged="r.travellerObj.avatarUrl = $event"
        )
    //- reservations-attachments(v-show="activeName === 'second'", :reservations="readyReservations")
    div.parraf(v-html="$t('lang.welcome.licancaburMsg')", v-if="nights > 7 && groups[0].reservations[0].hotel.id === '2'")

    div(v-if="isHotelChaiten")
      h4(v-html="chaitenTipsTitle" style="margin-bottom: 10px;")
      div.parraf(v-html="chaitenTipsText")

  div(v-if="error")
    img.error-img(src="@/../public/profile/logo.jpg")
    h3 {{ $t('lang.welcome.underMaintenance') }}
    //- label {{ error }}
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ReservationsAttachments from '../components/ReservationsAttachments.vue'
import Avatar from '../components/TravellerProfile/Avatar.vue'
import Header from '../components/Header.vue'
import moment from 'moment'
import { checkIsNearCheckin } from '../components/utils'
import { Loading } from 'element-ui'

export default {
  data () {
    return {
      token: null,
      error: null,
      loading: false,
      activeName: 'first',
      readyReservations: []
    }
  },
  components: {
    Avatar,
    Header,
    ReservationsAttachments
  },
  async created () {
    const loading = Loading.service()
    this.loading = true
    this.token = this.$route.params.token
    try {
      await this.getTraveller({ token: this.token })
    } catch (error) {
      this.error = error.message.split(':')[1]
    }
    this.loading = false
    // Vamos a buscar los viajeros que tienen los pasos completados
    this.groups.forEach(g => {
      g.reservations.forEach(reservation => {
        const birthday = moment().diff(reservation.travellerObj.birthday, 'years')
        if (reservation.isCompletedMin && reservation.hotel.needsMobilityPass && (birthday > 17 || birthday === null)) {
          this.readyReservations.push(reservation)
        }
      })
    })
    loading.close()
  },
  methods: {
    ...mapActions(['getTraveller']),
    goToCheckinForm (tokenProfile, confirmationNumber) {
      this.$ga.event('Traveller Profile', 'Entra al checkin de viajero', 'Confirmation Number', confirmationNumber)
      this.$router.push(`/travellerProfile/checkin/${tokenProfile}/confirmationNumber/${confirmationNumber}`)
    },
  },
  computed: {
    ...mapGetters(['groups', 'thanksMessage', 'getDisplayMessage']),
    isNearCheckin () {
      return checkIsNearCheckin(this.groups[0].reservations[0])
    },
    messageParraf () {
      const date = this.groups[0].reservations[0].checkin
      const date2 = moment(date).format('D/MM/YYYY')
      const checkinText = this.groups[0].reservations[0].confirmationNumber === 788804 ? this.$t('lang.welcome.checkinParraf') : ''
      return `${this.$t('lang.welcome.partOneParraf')}${date2}${this.$t('lang.welcome.partTwoParraf')}${checkinText}`
    },
    nights () {
      let nights = 0
      if (this.groups && this.groups.length > 0) {
        nights = this.groups[0].reservations[0].stayDays
      }
      return nights
    },
    isHotelChaiten() {
      return this.groups[0].reservations[0].hotel.id === "6"
    },
    lang() {
      return localStorage.getItem('lang')
    },
    chaitenTipsTitle() {
      return this.getDisplayMessage('CHAITEN_TIPS_TITLE', this.lang)
    },
    chaitenTipsText() {
      return this.getDisplayMessage('CHAITEN_TIPS_TEXT', this.lang)
    },
  }
}
</script>
<style lang="scss">
#TravellerProfile {
  text-align: center;
  .action-btn {
    color: white;
    background: #303133;
    padding: 8px;
    border-radius: 14px;
    cursor: pointer;
    width: 150px;
  }
  .welcome {
    text-align: left;
    font-weight: normal;
    margin-bottom: 12px;
    font-size: 20px;
  }
  .error-img {
    height: 80px;
    margin-top: 50px;
  }
  .check {
    height: 46px;
    width: 46px;
    border-radius: 50%;
    background: #e6a23c;
    color: white;
    margin-bottom: -62px;
    position: absolute;
    margin-left: 89px;
    margin-top: 93px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .parraf {
    text-align: left;
  }
  h1 {
    text-align: left;
    margin-top: 0;
  }
  .profile {
    padding: 24px;
  }
  .travellers-title {
    font-weight: bold;
    text-align: left;
    margin-bottom: 16px;
  }
  .icon-circle {
    width: 132px;
    height: 132px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #dbdde0;
    color: #4b5763;
  }
}
</style>
