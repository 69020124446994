<template lang="pug">
#Documents
  .upload-label(v-if="(!isResident && residentOption !== 'CHIL') && hotelCountries.includes('CHIL')")
    span {{ $t('lang.checkin.uploadPdiDoc') }}
    el-tooltip(class="item" effect="dark" placement="top-start")
      .tooltip ?
      template(slot="content")
        img.img-logo(src="../../../public/img/large-pdi.jpg")
  div(style="text-align: left; margin-top: 16px; margin-bottom: 8px; padding: 0 16px;")
    el-checkbox(v-model="isResident", style="font-size: 15px; margin-left: 4px; margin-bottom: 16px;", v-if="hotelCountries.length === 1") {{ residentTxt }}
    span(v-if="hotelCountries.length > 1")
      label {{ $t('lang.checkin.residency') }}
      el-select(v-model="residentOption", style="font-size: 15px; width: 100%;")
        el-option(v-for="item in residencyOptions" :key="item.value" :label="item.label" :value="item.value")
  FileUpload(v-model="PDI_FILE", :preview="pdiFilePreview" id="file-pdi" v-if="(!isResident && residentOption !== 'CHIL') && hotelCountries.includes('CHIL')")
  .box(v-if="!isResident && residentOption !== 'PER' && hotelCountries.includes('PER')")
    label {{ $t('lang.checkin.entryDatePeru') }}
    el-date-picker(type="date", style="width: 100%;", v-model="entryDate", format="dd/MM/yyyy", placeholder="dd/mm/aaaa", value-format="yyyy-MM-dd")
  .button-group(style="margin-top: 16px;")
    el-button#explora-btn.secondary(@click="goBack") {{ $t('lang.control.button.goBack') }}
    el-button#explora-btn.primary(@click="uploadFiles", :disabled="!canContinue") 
      span(v-if="PDI_FILE || isResident") {{ $t('lang.control.button.saveAndExit') }}
      span(v-else) {{ $t('lang.control.button.saveAndContinueLater') }}
</template>
<script>
import Header from '@/components/Header.vue'
import FileUpload from '@/components/FileUpload.vue';
import { mapActions, mapGetters } from 'vuex';
import { Loading } from 'element-ui';
import Compress from 'compress.js'

export default {
  props: ['canContinue', 'hotelCountries', 'seletedIndex'],
  components: {
    Header,
    FileUpload,
  },
  data () {
    return {
      PDI_FILE: null,
      entryDate: null,
      pdiPreview: null,
      isResident: false,
      residentOption: null,
      compressor: null,
    }
  },
  watch: {
    loadingDocs() {
      if (this.loadingDocs) {
        Loading.service({ fullscreen: true });
      } else {
        Loading.service().close();
      }
    },
    pdiFile() {
      if (this.pdiFile && !this.pdiFile.includes('resident_chile')) {
        this.createFileFromUrl(this.pdiFile)
          .then(file => {
            this.PDI_FILE = file;
          })
          .catch(error => {
            console.error('Error al obtener el archivo:', error);
          });
      }
    },
    pdiFilePreview() {
      if (this.pdiFilePreview) {
        this.pdiPreview = this.pdiFilePreview
      }
    },
    traveller() {
      if (this.traveller) {
        if (this.hotelCountry === 'PER') {
          this.isResident = this.traveller.isResidentPeru
          this.entryDate = this.reservation.entryDatePeru
        } else if (this.hotelCountry === 'ARG') {
          this.isResident = this.traveller.isResidentArgentina
        } else {
          this.isResident = this.traveller.isResidentChile
        }
      }
    },
  },
  computed: {
    ...mapGetters(['travellersCompanion', 'travellerProfileLink', 'pdiFile', 'passportFile', 'pdiFilePreview', 'isSigned', 'traveller', 'reservation', 'allReservations', 'loadingDocs']),
    residentTxt() {
      if (this.hotelCountries.includes('PER')) {
        return this.$t('lang.checkin.residentInPER')
      } else if (this.hotelCountries.includes('ARG')) {
        return this.$t('lang.checkin.residentInARG')
      } else {
        return this.$t('lang.checkin.residentInCHIL')
      }
    },
    residencyOptions() {
      let countries = this.hotelCountries.map(country => {
        return {
          value: country,
          label: this.$t(`lang.checkin.${country}`)
        }
      })

      countries.push({
        value: 'other',
        label: this.$t('lang.checkin.otherResidency')
      })

      return countries
    }
  },
  created() {
    this.compressor = new Compress()
    Loading.service({ fullscreen: true });
  },
  methods: {
    ...mapActions(['AddOrUpdateAttachment']),
    async createFileFromUrl(url) {
      const response = await fetch(url);
      const buffer = await response.arrayBuffer();
      const contentType = response.headers.get('content-type');
      const fileName = url.substring(url.lastIndexOf('/') + 1);
      const blob = new Blob([buffer], { type: contentType });
      const file = new File([blob], fileName, { type: contentType });
      return file;
    },
    goBack() {
      // window.location.replace(this.travellerProfileLink)
      this.$emit('changeStep', 1)
    },
    async upload() {
      let allOkey = true
      let blob = null
      if (this.PDI_FILE) {
        const fileReader = new FileReader()
        const file = this.PDI_FILE
        fileReader.readAsDataURL(file)

        // debemos comprobar si es archivo es imagen, para comprimirla, caso contrario blob = file
        const isImage = file.type.includes('image')
        if (isImage) {
          try {
            blob = await this.compressor.compress(file, {
              quality: 0.9,
              crop: false,
              maxWidth: 500,
              maxHeight: 500,
            })
          } catch (error) {
            blob = file
          }
        } else {
          blob = file
        }
      }

      const dataTicket = new FormData()

      dataTicket.append('reservation_id', this.reservation.id)
      dataTicket.append('token', this.$route.params.profileToken)

      if (this.PDI_FILE) {
        dataTicket.append(`file_type[0]`, 'PDI_FILE')
        dataTicket.append(`attachment[0]`, blob)
      }

      if (this.hotelCountries.includes('PER')) {
        dataTicket.append('is_resident_peru', (this.isResident || this.residentOption === 'PER'))
        dataTicket.append('entry_date_peru', this.entryDate)
      }
      if (this.hotelCountries.includes('CHIL')) {
        dataTicket.append('is_resident_chile', (this.isResident || this.residentOption === 'CHIL'))
      }
      if (this.hotelCountries.includes('ARG')) {
        dataTicket.append('is_resident_argentina', (this.isResident || this.residentOption === 'ARG'))
      }

      this.$notify({
        title: this.$t('lang.attachments.uploading'),
        message: this.$t('lang.attachments.upDescription'),
        type: 'info'
      })
      const resp = await this.AddOrUpdateAttachment(dataTicket)
      if (resp.status !== 200) allOkey = false
      this.$notify({
        title: resp.status === 200 ? this.$t('lang.attachments.ready') : this.$t('lang.attachments.problem'),
        message: resp.status === 200 ? this.$t('lang.attachments.uploaded') : this.$t('lang.attachments.generalError'),
        type: resp.status === 200 ? 'success' : 'error'
      })

      if (allOkey) {
        this.$store.commit('SET_HAS_PDI_FILE', { hasPdiFile: this.PDI_FILE !== null || this.isResident })
        this.$emit('changeStep', 5)
      }
    },
    async uploadFiles () {
      this.upload()
      // if (this.PDI_FILE === null && (!this.isResident && this.residentOption !== 'CHIL') && this.hotelCountries.includes('CHIL')) {
      //   this.$confirm(this.$t('lang.checkin.pdiWarning'), this.$t('lang.checkin.uploadPdiDoc'), {
      //     confirmButtonText: this.$t('lang.control.button.understood'),
      //     showCancelButton: false,
      //     type: 'warning',
      //   }).then(() => {
      //     this.upload()
      //   }).catch(() => {})
      // } else {
      //   this.upload()
      // }
    }
  }
}
</script>
<style lang="scss">
#Documents {
  .box {
    padding: 0 16px;
    text-align: left;
  }
  h3 {
    margin-bottom: 4px !important;
  }
  .el-checkbox__label {
    font-family: Avenir, Helvetica, Arial, sans-serif !important;
    font-size: 16px !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
  }
  .tooltip {
    border: 1px solid black;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    color: black;
    // margin-left: 16px;
  }
}
.el-message-box {
  width: 350px !important;
}
</style>
