<template lang="pug">
#Transport(v-if="transportData && reservations.length > 0")
  .hotel-select(v-if="hotels.length > 0")
    el-button#explora-btn.border(v-for="(hotel, ind) in hotels", :class="ind === index ? 'primary' : 'secondary'", @click="activateIndex(ind)")
      div(style="display: flex; align-items: center;")
        label {{ hotel.name }}
        .circle(v-if="isCompleted(legNumbers[ind])")
          .el-icon-check
  div
    .transport-inner(style="margin-top: 16px;", v-if="(reservations[index].packagesCodes.filter(pkc => ['3NBMCH', '4NBMCH'].includes(pkc)).length > 0 && type === 'ARRIVAL') || (reservations[index].packagesCodes.filter(pkc => ['3NCHBM', '4NCHBM'].includes(pkc)).length > 0 && type === 'DEPARTURE')")
      PNP-msg(:transportType="type", :reservation="reservations[index]")
      .button-group(style="margin-top: 16px;")
        el-button#explora-btn.secondary(@click="goBackPage") {{ $t('lang.control.button.goBack') }}
        el-button#explora-btn.primary(@click="goNext(legNumbers[index], index + 1 === reservations.length)", :disabled="checkinsTime[index] === '' || checkinsTime[index] === null") {{ $t('lang.control.button.continue') }}

    .transport-inner(style="margin-top: 16px;", v-else-if="reservations[index].packagesCodes.filter(pkc => ['3NPTNTCOCH', '4NPTNTCOCH', '3NCOCHPTNT', '4NCOCHPTNT'].includes(pkc)).length > 0 && type === 'ARRIVAL'")
      connect-msg(:reservation="reservations[index]")
      .button-group(style="margin-top: 16px;")
        el-button#explora-btn.secondary(@click="goBackPage") {{ $t('lang.control.button.goBack') }}
        el-button#explora-btn.primary(@click="goNext(legNumbers[index], index + 1 === reservations.length)", :disabled="checkinsTime[index] === '' || checkinsTime[index] === null") {{ $t('lang.control.button.continue') }}

    .transport-inner(v-else)
      div
        h1(v-if="type === 'ARRIVAL'") {{ $t('lang.transport.arrivalTitle') }}
        h1(v-if="type === 'DEPARTURE'") {{ $t('lang.transport.departureTitle') }}
        .form-radio
          el-radio.custom-radio(:disabled="reservations[index].travellerType === 'BASIC' || destinationsBlocked.includes(reservations[index].hotel.id)", v-model="itemActive[index]", label="airport" :id="'airPortCheck-' + type") {{ $t('lang.transport.airPortIcon') }}
          el-radio.custom-radio(:disabled="reservations[index].travellerType === 'BASIC' || destinationsBlocked.includes(reservations[index].hotel.id)", v-model="itemActive[index]", label="hotel" :id="'hotelCheck-' + type") {{ $t('lang.transport.hotelIcon') }}
          el-radio.custom-radio(:disabled="reservations[index].travellerType === 'BASIC' || destinationsBlocked.includes(reservations[index].hotel.id)", v-if="hotels[index].id === '1' && type === 'DEPARTURE'" v-model="itemActive[index]", label="border" :id="'borderCheck-' + type") {{ $t('lang.transport.borderIcon') }}
          el-radio.custom-radio(:disabled="reservations[index].travellerType === 'BASIC' || destinationsBlocked.includes(reservations[index].hotel.id)", v-model="itemActive[index]", label="dontNeed") {{ $t('lang.transport.dontNeed') }}
      .forms
        airport-form(:disabled="destinationsBlocked.includes(reservations[index].hotel.id)" :loadingRef="loadingRef" v-if="itemActive[index] === 'airport'", :confirmationNumber="reservations[index].confirmationNumber" ,:type="type", :changeStep="changeStep", :hotel="hotels[index]" :goBack="goBack", :goBackPage="goBackPage", :legNumber="legNumbers[index]", :isLast="index + 1 === reservations.length", :addIndex="addIndex")
        hotel-form(:disabled="destinationsBlocked.includes(reservations[index].hotel.id)" :loadingRef="loadingRef" v-if="itemActive[index] === 'hotel'", :confirmationNumber="reservations[index].confirmationNumber", :type="type", :changeStep="changeStep", :hotel="hotels[index]" :goBack="goBack", :goBackPage="goBackPage", :legNumber="legNumbers[index]", :isLast="index + 1 === reservations.length", :addIndex="addIndex", :dateTransport="type === 'ARRIVAL' ? reservations[index].checkin : reservations[index].checkout")
        border-form(:disabled="destinationsBlocked.includes(reservations[index].hotel.id)" :loadingRef="loadingRef" v-if="itemActive[index] === 'border'", :confirmationNumber="reservations[index].confirmationNumber" ,:type="type", :changeStep="changeStep", :hotel="hotels[index]" :goBack="goBack", :goBackPage="goBackPage", :legNumber="legNumbers[index]", :isLast="index + 1 === reservations.length", :addIndex="addIndex")
      .button-group(v-if="itemActive[index] === ''")
        el-button#explora-btn.secondary(@click="goBackPage") {{ $t('lang.control.button.goBack') }}
      el-form(
        style="margin-top: 16px;", v-if="itemActive[index] === 'dontNeed' || reservations[index].travellerType === 'BASIC'"
        ref="form", :model="form", :rules="rules"
      )
        .message(v-if="reservations[index].travellerType === 'BASIC'") {{ $t('lang.transport.basicTraveller') }}
        .message(v-if="reservations[index].hotel.id === '6' && this.type === 'ARRIVAL'", v-html="$t('lang.transport.chaltenMsg') ")
        .message(v-if="this.type === 'ARRIVAL' && arriveFile(lang) !== ''", v-html="arriveFile(lang)")
        el-form-item(prop="howArriveSelection")
          .input-label(v-if="this.type === 'ARRIVAL'") {{ $t('lang.transport.howWillArrive') }}
          el-select(v-model="form.howArriveSelection" v-if="this.type === 'ARRIVAL'")
            el-option(v-for="opt in $t('lang.transport.howWillArriveOptions')" :label="opt.label" :value="opt")
        el-form-item(prop="howArriveInput" v-if="form.howArriveSelection && form.howArriveSelection.withValue && this.type === 'ARRIVAL'")
          .input-label(style="margin-top: 8px;") {{ $t('lang.transport.howWillArriveSpecify') }}
          el-input(v-model="form.howArriveInput")
        el-form-item(prop="checkinsTime")
          .input-label(v-if="this.type === 'ARRIVAL'" style="margin-top: 8px;") {{ $t('lang.transport.selectCheckin') }}
          el-time-picker(v-model="form.checkinsTime[index]", v-if="this.type === 'ARRIVAL'", arrow-control, format="HH:mm", placeholder="Select time", v-on:input.native="validateTime" :picker-options="{ selectableRange: '06:00:00 - 23:30:00'}")
          el-switch(v-model="arrivesLunch[index]", v-if="this.type === 'ARRIVAL' && isLunchTime(form.checkinsTime[index])", :active-text="$t('lang.transport.arrivesLunch')", :inactive-text="$t('lang.transport.notArrivesLunch')")
        //- label(v-if="isEarlyTime(form.checkinsTime[index])") mensaje alerta temprano
        blocked-msg(v-if="destinationsBlocked.includes(reservations[index].hotel.id)", :isTransport="true")
        .button-group(style="margin-top: 16px;" v-else)
          el-button#explora-btn.secondary(@click="goBackPage") {{ $t('lang.control.button.goBack') }}
          el-button#explora-btn.primary(@click="SaveDontNeed(legNumbers[index], index + 1 === reservations.length)", :disabled="form.checkinsTime[index] === '' || form.checkinsTime[index] === null || isEarlyTime(form.checkinsTime[index])") {{ $t('lang.control.button.continue') }}
.loading-box(v-else)
  div(v-loading="true")
</template>
<script>
import TransportBox from '../TransportBox.vue'
import AirportForm from './TransportForm/AirportForm.vue'
import HotelForm from './TransportForm/HotelForm.vue'
import BorderForm from './TransportForm/BorderForm.vue'
import { mapGetters, mapActions } from 'vuex'
import BlockedMsg from './BlockedMsg.vue'
import PNPMsg from './PNPMsg.vue'
import ConnectMsg from './ConnectMsg.vue'
import moment from 'moment'
import { stepValues } from './formSteps'

export default {
  props: ['type', 'changeStep', 'destinationsBlocked', 'loadingRef'],
  data () {
    return {
      itemActive: [],
      reservations: [],
      legNumbers: [],
      hotels: [],
      index: 0,
      arrivesLunch: [],
      partyApply: [],
      form: {
        howArriveSelection: null,
        howArriveInput: null,
        checkinsTime: [],
      },
      rules: {
        howArriveSelection: [{ required: this.type === 'ARRIVAL', message: this.$t('lang.basicInformation.errors.field'), trigger: 'blur' }],
        howArriveInput: [{ required: this.type === 'ARRIVAL', message: this.$t('lang.basicInformation.errors.field'), trigger: 'blur' }],
        checkinsTime: [{ required: this.type === 'ARRIVAL', message: this.$t('lang.basicInformation.errors.field'), trigger: 'blur' }],
      }
    }
  },
  components: {
    TransportBox,
    AirportForm,
    HotelForm,
    BorderForm,
    BlockedMsg,
    ConnectMsg,
    PNPMsg
  },
  computed: {
    ...mapGetters(['transportData', 'confirmationNumber']),
    lang () {
      const lang = localStorage.getItem('lang')
      return lang
    }
  },
  methods: {
    ...mapActions(['CancellTransport', 'modifyTravellerStepStatus', 'getTransport']),
    arriveFile (lang) {
      if (lang === 'es' && this.reservations[this.index].hotel.howToArriveFileEs) return this.$t('lang.transport.howToArriveFile').replace('%filelink%', this.reservations[this.index].hotel.howToArriveFileEs)
      if (lang === 'en' && this.reservations[this.index].hotel.howToArriveFileEn) return this.$t('lang.transport.howToArriveFile').replace('%filelink%', this.reservations[this.index].hotel.howToArriveFileEn)
      if (lang === 'pt' && this.reservations[this.index].hotel.howToArriveFilePt) return this.$t('lang.transport.howToArriveFile').replace('%filelink%', this.reservations[this.index].hotel.howToArriveFilePt)
      else return ''
    },
    isLunchTime (time) {
      if (time) {
        const htime = moment(time).format('HH')
        const mtime = moment(time).format('mm')

        if (htime && mtime) {
          if (parseInt(htime) === 13) return true
          if (parseInt(htime) === 14 && parseInt(mtime) === 0) return true
        }
      }
      return false
    },
    isEarlyTime (time) {
      // if (time) {
      //   const htime = moment(time).format('HH')
      //   const mtime = moment(time).format('mm')

      //   if (htime && mtime) {
      //     if (parseInt(htime) >= 0 && parseInt(htime) <= 12) return true
      //   }
      // }

      return false
    },
    validateTime (event) {
      if (
        event.target.value &&
        event.target.value.length === 2 &&
        !event.target.value.includes(':') &&
        event.data !== null
      ) {
        event.target.value += ':'
      }
    },
    showForm (form) {
      this.itemActive = form
    },
    goBack () {
      this.itemActive = ''
    },
    activateIndex (ind) {
      this.index = ind
    },
    goBackPage () {
      if (this.index === 0) {
        this.$emit('previousStep')
      } else {
        this.index -= 1
      }
    },
    async goNext (legNumber, isLast) {
      this.loadingRef.showLoading();
      const currentStep = this.type === 'ARRIVAL' ? stepValues.TRANSPORT_ARRIVAL : stepValues.TRANSPORT_DEPARTURE
      await this.modifyTravellerStepStatus({ token: this.$route.params.profileToken, travellerId: this.transportData.idTraveller, stepNumber: currentStep, status: 'OK', confirmationNumber: this.reservations[this.index].confirmationNumber, legNumber: legNumber })
      if (isLast) {
        this.$emit('nextStep')
      } else {
        this.index += 1
      }
    },
    addIndex () {
      this.index += 1
    },
    isCompleted (legNumber) {
      const step = this.type === 'ARRIVAL' ? 2 : 3
      const stepHotel = this.transportData.reservationConsulted.steps.filter(st => st.step === step && st.legNumber === legNumber)
      if (stepHotel.length > 0) {
        return stepHotel[0].status === 'OK'
      } else {
        return false
      }
    },
    async SaveDontNeed (legNumber, isLast) {
      const checkinTime = this.form.checkinsTime[this.index] ? moment(this.form.checkinsTime[this.index]).format('HH:mm') : null

      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          const confirmationNumbers = [this.confirmationNumber]
          let canContinue = true

          const h = this.$createElement
          const selection = []

          selection.push(h('el-checkbox', {
            props: {
              key: this.confirmationNumber,
              value: true,
              disabled: true
            },
            style: {
              width: '100%'
            }
          }, `${this.transportData.firstName} ${this.transportData.lastName}`))
          this.transportData.reservationConsulted.partyConfirmations.forEach((res) => {
            this.partyApply.push({
              reservation: res,
              value: false
            })
          })

          this.transportData.reservationConsulted.partyConfirmations.forEach((res) => {
            const reservationCheckVal = this.partyApply.find((obj) => obj.reservation === res)
            selection.push(h('el-checkbox', {
              props: {
               key: res.confirmationNumber
             },
             domProps: { value: reservationCheckVal.value },
             on: { input: (value) => { reservationCheckVal.value = value } }
            }, `${res.travellerObj.firstName} ${res.travellerObj.lastName}`))
          })

          if (parseInt(this.transportData.reservationConsulted.partyCode) === parseInt(this.confirmationNumber) && this.transportData.reservationConsulted.partyConfirmations.length > 0) {
            canContinue = false
            await this.$msgbox({
              title: this.$t('lang.applyTransportParty.message'),
              message: h('div', null, [
                h('p', null, this.$t('lang.applyTransportParty.description')),
                h('br', null, ''),
                selection
              ]),
              showCancelButton: true,
              confirmButtonText: 'Aplicar',
              confirmButtonClass: 'apply-transport-info',
              cancelButtonText: 'Cancelar',
              beforeClose: (action, instance, done) => {
                if (action === 'confirm') {
                  canContinue = true
                  this.partyApply.filter((p) => p.value === true).forEach(p => {
                    confirmationNumbers.push(p.reservation.confirmationNumber)
                  })
                  done()
                } else {
                  done()
                }
              }
            }).then(() => {}).catch(() => {})
          }
          if (canContinue) {      
            this.loadingRef.showLoading();
            const nextStep = this.type === 'ARRIVAL' ? 2 : 3
            await this.modifyTravellerStepStatus({ token: this.$route.params.profileToken, travellerId: this.transportData.idTraveller, stepNumber: nextStep - 1, status: 'OK', confirmationNumber: this.reservations[this.index].confirmationNumber, legNumber: legNumber })
            if (isLast) {
              this.changeStep(nextStep)
            } else {
              this.index += 1
            }
            let howArrive = this.type === 'ARRIVAL' ? this.form.howArriveSelection.value : null
            if (howArrive === 'other') {
              howArrive = this.form.howArriveInput
            }
            if (this.type === 'ARRIVAL' && this.form.howArriveSelection.withValue && howArrive !== 'other') {
              howArrive = `${this.form.howArriveSelection.value}: ${this.form.howArriveInput}`
            }
            const resp = await this.CancellTransport({
              travellerId: this.transportData.idTraveller,
              queryType: this.type,
              // confirmationNumber: this.reservations[this.index].confirmationNumber,
              confirmationNumbers: confirmationNumbers,
              legNumber: legNumber,
              checkin: checkinTime,
              howArrive: howArrive,
              arrivesToLunch: this.arrivesLunch[this.index] && this.isLunchTime(this.form.checkinsTime[this.index]) ? this.arrivesLunch[this.index] : false
            })
            this.loadingRef.hideLoading();
            if (resp === true) {
              this.$notify({
                title: this.$t('lang.saved.title'),
                type: 'success'
              })
            } else {
              this.$notify({
                title: this.$t('lang.errorSave.title'),
                message: this.$t('lang.errorSave.message'),
                type: 'error'
              })
            }
          }
        }
      })
    }
  },
  async created () {
    await this.getTransport({
      token: this.$route.params.profileToken,
      confirmationNumber: this.$route.params.confirmationNumber,
    })
    if (this.transportData.reservationConsulted.crossingGroup) {
       if (this.type === 'ARRIVAL') {
        this.reservations.push(this.transportData.reservationConsulted)
       } else {
        this.transportData.aditionalReservations.forEach(reservation => {
          this.reservations.push(reservation)
        })
       }
    } else if (this.transportData.reservationConsulted.exploraConnectId) {
      if (this.type === 'ARRIVAL') {
        this.reservations.push(this.transportData.reservationConsulted)
        this.transportData.aditionalReservations.forEach(reservation => { this.reservations.push(reservation) })
       } else {
        this.transportData.aditionalReservations.forEach(reservation => { this.reservations.push(reservation) })
        // this.reservations.push(this.transportData.reservationConsulted)
       }
    } else {
      this.reservations.push(this.transportData.reservationConsulted)
      this.transportData.aditionalReservations.forEach(reservation => {
        this.reservations.push(reservation)
      })
    }
    let inx = 0
    this.reservations.forEach(reservation => {
      this.legNumbers[inx] = reservation.legNumber
      this.hotels[inx] = reservation.hotel
      if (moment(reservation.checkin).format('HH:mm') !== '00:00') {
        this.form.checkinsTime[inx] = reservation.checkin
      }
      if (reservation.specialTransportCrossing) {
        this.hotels[inx] = { ...this.transportData.reservationConsulted.hotel }
        this.hotels[inx].name = 'Uyuni / Calama'
      }
      
      if (reservation.travellerType !== 'BASIC') {
        if (this.type === 'ARRIVAL') {
          if (reservation.arrivalStatus === 'CANCELLED') {
            this.itemActive[inx] = 'dontNeed'
            this.form.howArriveSelection = reservation.howArrive
          } else if (
            reservation.arrivalDestinationType &&
            reservation.arrivalDestinationType === 'AIRPORT'
          ) {
            this.itemActive[inx] = 'airport'
          } else if (
            reservation.arrivalDestinationType &&
            reservation.arrivalDestinationType === 'HOTEL'
          ) {
            this.itemActive[inx] = 'hotel'
          }
        } else if (this.type === 'DEPARTURE') {
          if (reservation.departureStatus === 'CANCELLED') {
            this.itemActive[inx] = 'dontNeed'
          } else if (
            reservation.departureDestinationType &&
            reservation.departureDestinationType === 'AIRPORT'
          ) {
            this.itemActive[inx] = 'airport'
          } else if (
            reservation.departureDestinationType &&
            reservation.departureDestinationType === 'HOTEL'
          ) {
            this.itemActive[inx] = 'hotel'
          }
        }
      }
      inx += 1
    })
  }
}
</script>
<style lang="scss">
#Transport {
  padding: 8px 16px;
  text-align: left;
  .el-button.is-disabled, .el-button.is-disabled:focus, .el-button.is-disabled:hover {
    background-color: #303133bd !important;
  }
  .boxes {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .form-radio {
    display: grid;
  }
  .custom-radio {
    margin-bottom: 16px;
  }
  .button-group-one {
    display: flex;
    padding-left: 25px;
    margin: 20px 0;
  }
  .border {
    border-radius: 5px;
    border: black 0.5px solid;
    padding: 8px;
  }
  .circle {
    width: 12px;
    height: 12px;
    font-size: 10px;
    border: 1px black solid;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f99921;
    color: white;
    margin-left: 8px;
  }
  .el-date-editor.el-input, .el-date-editor.el-input__inner, .el-input--suffix, .el-input__inner, .el-select {
    width: 100%;
  }
  .message {
    font-size: 13px;
    font-style: italic;
    padding: 30px 0px;
  }
}
.el-switch__label.is-active {
  color: #121212 !important;
  font-weight: bold !important;
}
.el-switch.is-checked .el-switch__core {
  border-color: #121212 !important;
  background-color: #121212 !important;
}
.el-switch {
  margin-top: 16px;
}
</style>
