import moment from 'moment'

// --- Métodos bloqueos
export const checkAllIsBlocked = (reservation, byCheckout = false) => {
    let isblocked = false
    let lastCheckin = byCheckout === false ? moment(reservation.checkin).format() : moment(reservation.checkout).format()
    let notCancelledExist = false
    if (reservation.reservationStatus !== 'CANCELED') {
        notCancelledExist = true
    }
    const nearDate = moment().format()
    reservation.aditionalReservations.forEach(ar => {
        const arCheckin = moment(ar.checkin).format()
        if (moment(arCheckin).isAfter(lastCheckin)) {
            lastCheckin = arCheckin
        }
        if (ar.reservationStatus !== 'CANCELED') {
            notCancelledExist = true
        }
    })
    if (moment(nearDate).isAfter(lastCheckin) || !notCancelledExist) {
        isblocked = true
    }
    return isblocked
}

export const checkIsNearCheckin = (reservation) => {
    // Obtiene la fecha actual
    const currentDate = moment();

    // Obtiene la fecha del checkin del objeto reservation
    const checkinDate = moment(reservation.checkin, 'YYYY-MM-DDTHH:mm');
    const minSteps = reservation.steps.filter((rs) => rs.step === 1 || rs.step === 2 || rs.step === 3 || rs.step === 4)
    const minStepsCompleted = minSteps.filter((rs) => rs.status === 'OK')
    // Define el límite en días para considerar que está cercano
    const confirmationsNumbers = [
        820662,

        826855,
        826852,
        826853,
        826854,

        830389,
        830390,
        830391,

        836806,

        840605,

        842325,
        842326,
        842327,

        843682,
    ]
    const daysBeforeCheckin = reservation.hotel.daysBeforeCheckin;

    return (
        reservation.hotel.webCheckinEnabled &&
        confirmationsNumbers.includes(reservation.confirmationNumber) &&
        reservation.aditionalReservations.length === 0 &&
        // confirmationsNumbers.includes(reservation.confirmationNumber)
        minSteps.length === minStepsCompleted.length &&
        (
            (checkinDate.diff(currentDate, 'days') <= daysBeforeCheckin &&
            checkinDate.isSameOrAfter(currentDate, 'day')) ||
            (checkinDate.isSame(currentDate, 'day') ||
            checkinDate.isSame(currentDate.add(1, 'day'), 'day'))
        )
    )
    // return reservation.partyCode === '809346' || reservation.partyCode === '809117' || reservation.partyCode === '809116' || reservation.partyCode === '809115' || reservation.partyCode === '809118' || reservation.partyCode === '809119'
}

export const checkReservationConsultedIsblocked = (reservation) => {
    const lastCheckin = moment(reservation.checkin).format()

    const nearDate = moment().format()
    if (moment(nearDate).isAfter(lastCheckin) || reservation.reservationStatus === 'CANCELED') {
        return true
    }
    return false
}

export const checkDestinationsBlocked = (reservation, aditionalReservations) => {
    const nearDate = moment().add(2, 'days').format()
    const destinationsBlocked = []
    const reservationsBlocked = ['CANCELED', 'WAITLISTED']

    if (reservationsBlocked.includes(reservation.reservationStatus) || moment(nearDate).isAfter(moment(reservation.checkin).format())) {
        destinationsBlocked.push(reservation.hotel.id)
    }
    aditionalReservations.forEach(ar => {
        if (reservationsBlocked.includes(ar.reservationStatus) || moment(nearDate).isAfter(moment(ar.checkin).format())) {
            destinationsBlocked.push(reservation.hotel.id)
        }
    })
    return destinationsBlocked
}
